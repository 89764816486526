import React from "react";
import SEO from "../components/SEO";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import integrationBG from "../images/v2/home/integration-bg.svg";
import HeaderTypography from "../components/v2/Typography/HeaderTypography";
import ParagraphTypography from "../components/v2/Typography/ParagraphTypography";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme) => ({
  integrationRoot: {
    background: `url(${integrationBG})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
  },
  Personalheader: {
    fontSize: "20px",
    fontWeight: "600",
    color: "#000",
    // TextAlign: "left",
  },
}));

const PrivayPage = () => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <SEO
        canonical="/privacy"
        description="Privacy Policy Statement highlights summarising our personal data processing practices by CRM and your related rights. Read more about Pepper Cloud's Privacy Policy in detail."
        pathname="/privacy"
        title="Privacy Policy | Pepper Cloud CRM"
        keywords="Privacy Policy"
      />
      <div className={classes.integrationRoot}>
        <HeaderTypography
          bgcolor="transparent"
          color="common.white"
          my={4}
          textAlign={{ sm: "center", xs: "center" }}
        >
          Privacy Policy
        </HeaderTypography>
      </div>
      <Container component="section">
        <Box component="section" mb={6}>
          <div>
            <div className="pc-website-privacy-content">
              <Typography variant="subtitle1">
                Last updated: 15 August, 2024
              </Typography>
              <ParagraphTypography>
                <i>
                  This privacy statement is subject to change and updated as
                  required. The amended privacy statement will be published on
                  our website.
                </i>
              </ParagraphTypography>
              <ParagraphTypography>
                Pepper Cloud Pte. Ltd. (hereinafter “Pepper Cloud”, “us”, “we”,
                or “our”) is committed to respecting your privacy and is
                compliant with all data protection and privacy laws governing
                Singapore. Pepper Cloud’s privacy policy statement is designed
                to help users (hereinafter “you” or “individuals” refers to
                individual users of our services, while “customer” refers to
                organisations of businesses that use our CRM system) understand
                how we collect, use and protect your information when you visit
                our website and when you generally request information on our
                products and services - whether in person, via business card,
                email, online ads, trade booth, post, telephone, fax or other
                communication channels
              </ParagraphTypography>
              <ParagraphTypography>
                For the purposes of this privacy policy "personal data" means
                any information relating to an identified or identifiable
                individual.
              </ParagraphTypography>
              <br />
              <Typography
                variant="subtitle1"
                component="h2"
                className={classes.Personalheader}
              >
                Personal information collection
              </Typography>
              <ParagraphTypography>
                Pepper Cloud endeavours to collect and use your personal
                information only with your knowledge and consent. We collect
                this information typically when you place an order, use Pepper
                Cloud's products and services, make customer inquiries, register
                for or request product information, submit a job application,
                respond to communications from us (such as questionnaires or
                surveys), interact with our online advertisements or social
                media presence.
              </ParagraphTypography>
              <ParagraphTypography>
                Before collecting personal data, we ensure that we inform
                individuals of the purposes for collection and obtain consent.
                We request consent through various methods, including:
              </ParagraphTypography>
              <ul>
                <li>
                  <ParagraphTypography>
                    Consent checkbox in web forms and contact forms.
                  </ParagraphTypography>
                </li>
                <li>
                  <ParagraphTypography>
                    Subscribe/Unsubscribe option in emails/messages.
                  </ParagraphTypography>
                </li>
                <li>
                  <ParagraphTypography>
                    Opt-in/Opt-out options in record creation forms.
                  </ParagraphTypography>
                </li>
              </ul>
              <ParagraphTypography>
                The personal information we typically collect includes your
                name, company name, email address, telephone numbers, and any
                other relevant information that is required and collected via
                requests, registrations or through surveys.
              </ParagraphTypography>
              <ParagraphTypography>
                If you request data correction, we process it accordingly.
              </ParagraphTypography>

              <ParagraphTypography>
                On occasion, Pepper Cloud may also purchase mailing lists for
                the purposes of direct marketing. However, we will only do this
                from authorised and registered providers that maintain
                comprehensive privacy policies safeguarding your rights as an
                individual. Once we obtain your data, it is processed and
                recorded on secure databases, accessible only by authorised
                Pepper Cloud personnel.
              </ParagraphTypography>

              <ParagraphTypography>
                If you would like to opt out of the mailing list or unsubscribe
                from all communications from Pepper Cloud, you can send us an
                email at{" "}
                <a href="mailto:support@peppercloud.com">
                  support@peppercloud.com
                </a>
              </ParagraphTypography>

              <Typography
                variant="subtitle1"
                component="h2"
                className={classes.Personalheader}
              >
                Information collected by our customers
              </Typography>
              <ParagraphTypography>
                Pepper Cloud provides CRM services to businesses and
                organisations (our "business customers"). These business
                customers may collect and manage personal data about their own
                customers or users when using our products and services. In such
                cases:
              </ParagraphTypography>
              <ul>
                <li>
                  <ParagraphTypography>
                    Our business customers are considered data controllers and
                    are responsible for complying with any regulations or laws
                    that require providing notice, disclosure, and/or obtaining
                    consent prior to collecting their customers' personal data.
                  </ParagraphTypography>
                </li>
                <li>
                  <ParagraphTypography>
                    We do not control the content of our business customers' web
                    pages or the types of personal data that our customers may
                    choose to collect or manage using our services.
                  </ParagraphTypography>
                </li>
                <li>
                  <ParagraphTypography>
                    Our business customers control and are responsible for
                    correcting, deleting or updating the information they
                    process using our services. And our customers are also
                    responsible for how and what they communicate with their
                    users and their mode of communication.
                  </ParagraphTypography>
                </li>
              </ul>
              <br />
              <Typography
                variant="subtitle1"
                component="h2"
                className={classes.Personalheader}
              >
                Usage of personal information
              </Typography>
              <ParagraphTypography>
                Pepper Cloud collects and uses your data for the following
                purposes:
              </ParagraphTypography>
              <ul>
                <li>
                  <ParagraphTypography>
                    To provide you with the information you have requested.
                  </ParagraphTypography>
                </li>
                <li>
                  <ParagraphTypography>
                    To provide and support your use of Pepper Cloud products and
                    services.
                  </ParagraphTypography>
                </li>
                <li>
                  <ParagraphTypography>
                    To provide information about Pepper Cloud products and
                    services, seminars, etc. and to inform you about other
                    information on Pepper Cloud.
                  </ParagraphTypography>
                </li>
                <li>
                  <ParagraphTypography>
                    To inform you of winning a competition, sending a prize,
                    etc.
                  </ParagraphTypography>
                </li>
                <li>
                  <ParagraphTypography>
                    To enhance and improve Pepper Cloud products and services.
                  </ParagraphTypography>
                </li>
                <li>
                  <ParagraphTypography>
                    To send thank-you letters for purchasing our products and
                    services.
                  </ParagraphTypography>
                </li>
                <li>
                  <ParagraphTypography>
                    To respond to your enquiries.
                  </ParagraphTypography>
                </li>
                <li>
                  <ParagraphTypography>
                    To identify and contact you when necessary, etc.
                  </ParagraphTypography>
                </li>
              </ul>
              <ParagraphTypography>
                Pepper Cloud may record telephone calls, webinars, online
                training sessions, and video conferences with users in order to
                accurately understand the content of the enquiries and to
                improve the quality of our correspondence with users.
              </ParagraphTypography>
              <ParagraphTypography>
                Pepper Cloud will not use the information provided by you for
                any purpose other than the above purposes or any purpose to
                which you have agreed upon providing your information. In case
                any other purpose arises, we will notify you in advance and seek
                your consent.
              </ParagraphTypography>
              <ParagraphTypography>
                Pepper Cloud adheres to the National Do Not Call (DNC) Registry
                regulations. We do not send marketing messages through voice or
                text to individuals registered in the DNC registry unless we
                have obtained clear and unambiguous consent.
              </ParagraphTypography>
              <ParagraphTypography>
                You are entitled to refuse the provision of user information.
                However, please note that in such cases, Pepper Cloud may not be
                able to continue to provide access to Pepper Cloud services.
              </ParagraphTypography>
              <ParagraphTypography>
                In addition, user information obtained by us may be used in
                combination with user information already in our possession or
                obtained from partners, including contractors.
              </ParagraphTypography>
              <br />
              <Typography
                variant="subtitle1"
                component="h2"
                className={classes.Personalheader}
              >
                Protection and disclosure of information
              </Typography>
              <ParagraphTypography>
                Pepper Cloud does not rent, sell, trade, or otherwise share your
                information with any third-party organisations. We may in the
                future decide to tell you about the products and services of
                carefully selected third parties and allow you to receive
                advertising and marketing information from those selected third
                parties, but we will not pass control of your personal
                information to any third parties.
              </ParagraphTypography>
              <ParagraphTypography>
                We may disclose information about you
              </ParagraphTypography>
              <ul>
                <li>
                  <ParagraphTypography>
                    To companies and departments within Pepper Cloud Group,
                    subject to the terms of this privacy policy; and
                  </ParagraphTypography>
                </li>
                <li>
                  <ParagraphTypography>
                    In the event that we undergo re-organisation or are sold to
                    a third party, in which case you agree that any personal
                    information we hold about you may be transferred to that
                    re-organised company or third party for the purposes
                    described herein and subject to the terms of this privacy
                    policy.
                  </ParagraphTypography>
                </li>
              </ul>
              <ParagraphTypography>
                We may release information when Pepper Cloud believes, in good
                faith, that such release is legitimately necessary to:
              </ParagraphTypography>
              <ul>
                <li>
                  <ParagraphTypography>
                    Comply with the law,{" "}
                  </ParagraphTypography>
                </li>
                <li>
                  <ParagraphTypography>
                    Enforce or apply the terms of any of our user agreements{" "}
                  </ParagraphTypography>
                </li>
                <li>
                  <ParagraphTypography>
                    Protect the rights, property or safety of Pepper Cloud, our
                    visitors, or others.
                  </ParagraphTypography>
                </li>
              </ul>
              <ParagraphTypography>
                Pepper Cloud may also be obliged to disclose your personal
                information to meet any legal or regulatory requirements or
                obligations in accordance with applicable law.
              </ParagraphTypography>
              <ParagraphTypography>
                We implement appropriate technical and organisational measures
                to ensure the security of personal data in our possession,
                protecting it from unauthorised access, modification,
                disclosure, or use, whether in hardcopy or electronic form.
              </ParagraphTypography>
              <ParagraphTypography>
                Upon request, Pepper Cloud can also provide information on how
                the individual’s personal data has been used or disclosed in the
                past year.
              </ParagraphTypography>
              <br />
              <Typography
                variant="subtitle1"
                component="h2"
                className={classes.Personalheader}
              >
                Duration of data retention
              </Typography>
              <ParagraphTypography>
                Here are the terms of data retention:
              </ParagraphTypography>
              <ul>
                <li>
                  <ParagraphTypography>
                    We retain personal data no longer than necessary to fulfil
                    the purposes for which the personal data is processed
                  </ParagraphTypography>
                </li>
                <li>
                  <ParagraphTypography>
                    If the processing is based on the consent, we retain data
                    until you withdraw your consent
                  </ParagraphTypography>
                </li>
                <li>
                  <ParagraphTypography>
                    If the processing is based on a legitimate interest of
                    Pepper Cloud, we retain data until you object to such
                    processing.
                  </ParagraphTypography>
                </li>
              </ul>
              <ParagraphTypography>
                However, we will store personal data for a longer period where
              </ParagraphTypography>
              <ul>
                <li>
                  <ParagraphTypography>
                    We are required by law to retain your personal data or
                  </ParagraphTypography>
                </li>
                <li>
                  <ParagraphTypography>
                    Your personal data is required for Pepper Cloud to assert or
                    defend against legal claims. In this event, we will retain
                    your personal data until the end of the relevant retention
                    period or until the claims in question have been settled.
                  </ParagraphTypography>
                </li>
              </ul>
              <ParagraphTypography>
                The retained personal data will be securely destroyed when no
                longer needed.
              </ParagraphTypography>
              <ParagraphTypography>
                If you wish to request that we no longer use your collected
                data, please email us at{" "}
                <a href="mailto:support@peppercloud.com">
                  support@peppercloud.com
                </a>
              </ParagraphTypography>
              <br />
              <Typography
                variant="subtitle1"
                component="h2"
                className={classes.Personalheader}
              >
                Third party API integration
              </Typography>
              <ParagraphTypography>
                Pepper Cloud use of information received from third party
                integrations (e.g., Google, Microsoft) will adhere to their
                respective API services and usage policies. For Google API
                integration, Pepper Cloud use and transfer of information will
                adhere to the{" "}
                <a
                  href="https://developers.google.com/terms/api-services-user-data-policy"
                  target="_"
                >
                  Google API Services User Data Policy
                </a>
                , including the limited use requirements.
              </ParagraphTypography>
              <br />
              <Typography
                variant="subtitle1"
                component="h2"
                className={classes.Personalheader}
              >
                Other websites and privacy concerns
              </Typography>
              <ParagraphTypography>
                Pepper Cloud’s website may contain links to other company and
                organisational websites that are not owned or operated by us. We
                are not responsible for the privacy practices or the content of
                such websites. For more information on your privacy rights with
                regard to these sites, please refer to the privacy policies of
                each respective site that you visit.
              </ParagraphTypography>
              <br />
              <Typography
                variant="subtitle1"
                component="h2"
                className={classes.Personalheader}
              >
                Changes and updates to privacy policy
              </Typography>
              <ParagraphTypography>
                We may update this privacy policy from time to time to reflect
                changes in our practices or for other operational, legal, or
                regulatory reasons. We will notify you of any material changes
                by posting the updated privacy policy on our website and
                updating the "Last Updated" date at the top of this privacy
                policy.
              </ParagraphTypography>
              <br />
              <Typography
                variant="subtitle1"
                component="h2"
                className={classes.Personalheader}
              >
                Contact us
              </Typography>
              <ParagraphTypography>
                If you have questions or need to contact us about this privacy
                policy, please email us at{" "}
                <a href="mailto:support@peppercloud.com">
                  support@peppercloud.com
                </a>
              </ParagraphTypography>
            </div>
          </div>
        </Box>
      </Container>
    </React.Fragment>
  );
};

export default PrivayPage;
